import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isCollapse: false,  // 侧边栏关闭和展开
    themeStyle:localStorage.getItem("themeStyle") || "dark",
    lang:localStorage.getItem("langData") || 'th',
    currentPath:"",
    loginShow:false,
    showNum:0,   // 0  登录  1 注册  2 验证码 3 找回密码 
    moveState:"turn-on",
    moveClass:"turn-on",
    goForward:true,
    gongGaoList:[],
    walletmy:null,
    dashboard:{},
    noMsg:false,
    addrlist:[],
    cateproductList:null,
    activeKey:0,  // 竞拍侧边导航的 index
    active:"1" ,   // 竞拍页面的  active tab 
    taiprice:'',
    cardList:[],
    bannerList:[],
    jiqigoodList:[],
    homewinnerList:[]
  },
  getters:{
    user(state){
      return state.dashboard;
    }
  },
  mutations: {
    switchCollapse(state){
      state.isCollapse = !state.isCollapse;
    },
    switchthemeStyle(state,payload){
      localStorage.setItem("themeStyle",payload);
      state.themeStyle = payload;
    },
    changeLoginShow(state,payload){
      state.loginShow = payload;
    },
    changeShowNum(state,payload){
      state.showNum = payload;
    },
    setMoveState(state,payload){
      state.moveState = payload;
    },
    setMoveClass(state,payload){
      state.moveClass = payload;
    },
    setgoForward(state,payload){
      state.goForward = payload;
    },
    setgongGaoList(state,payload){
      state.gongGaoList = payload;
    },
    setBannerList(state,payload){
      state.bannerList = payload;
    },
    setwalletmy(state,payload){
      state.walletmy = payload;
    },
    setdashboard(state,payload){
      state.dashboard = payload;
    },
    setnoMsg(state,payload){
      state.noMsg = payload;
    },
    setaddrlist(state,payload){
      state.addrlist = payload;
    },
    changecateproductList(state,payload){
      state.cateproductList = payload;
    },
    changeactiveKey(state,payload){
      state.activeKey = payload
    },
    changeactive(state,payload){
      state.active = payload
    },
    changetaiprice(state,payload){
      state.taiprice = payload
    },
    setCardList(state,payload){
      state.cardList = payload
    },
    setjiqigoodList(state,payload){
      state.jiqigoodList = payload
    },
    sethomewinnerList(state,payload){
      state.homewinnerList = payload
    },
    setLang(state,payload){
      state.lang = payload;
    }

    
  },
  actions: {
  },
  modules: {
  }
})
