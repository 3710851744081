<template>
    <van-pull-refresh
        v-model="refresh"
        @refresh="onRefresh"
    >
        <van-list
            :immediate-check="false"
            v-model="loading"
            :finished="finished"
            :finished-text="$t('common.nomore')"
            @load="loadmore"
            class="my-list van-clearfix"
            :class="classobj"
            id="listarea"
            :offset="5"
        >
            <slot>

            </slot>
        </van-list>
    </van-pull-refresh>
</template>

<script>
export default {
    props: {
        listadd:Function,
        refreshajax:{
            type:Function,
            default:function(callback){
                callback()
            }
        },
        classobj:String
    },
    data(){
        return {
            finished:false,
            loading:false,
            refresh:false,
        }
    },
    methods:{
        onRefresh(){
            setTimeout(()=>{
                this.refreshajax(()=>{
                    this.refresh = false;
                });
            },500)
        },
        loadmore(){
            
            this.loading = true; 
            setTimeout(()=>{
                this.listadd(()=>{
                    this.loading  = false;
                },()=>{
                    this.finished = true;  // 加载结束 
                })
            },500)
        }
    }
}
</script>
