"use strict";

exports.__esModule = true;
exports.default = void 0;
var _default = {
  name: 'ชื่อ',
  tel: 'โทรศัพท์',
  save: 'บันทึก',
  confirm: 'ยืนยัน',
  cancel: 'ยกเลิก',
  delete: 'ลบ',
  complete: 'สำเร็จ',
  loading: 'กำลังดำเนินการ...',
  telEmpty: 'รุณากรอกหมายเลขโทรศัพท์',
  nameEmpty: 'กรุณากรอกชื่อของคุณ',
  nameInvalid: 'กรุณาใส่ชื่อให้ถูกต้อง',
  confirmDelete: 'คุณแน่ใจว่าต้องการลบ',
  telInvalid: 'กรุณาใส่หมายเลขโทรศัพท์ให้ถูกต้อง',
  vanCalendar: {
    end: 'เสร็จสิ้น',
    start: 'เริ่ม',
    title: 'เลือกวันที่',
    confirm: 'ยืนยัน',
    startEnd: 'เริ่ม/เสร็นสิ้น',
    weekdays: ['เดือน', 'หนึ่ง', 'สอง', 'สาม', 'สี่', 'ห้า', 'หก'],
    monthTitle: function monthTitle(year, month) {
      return year + "\u5E74" + month + "\u6708";
    },
    rangePrompt: function rangePrompt(maxRange) {
      return "\u9009\u62E9\u5929\u6570\u4E0D\u80FD\u8D85\u8FC7 " + maxRange + " \u5929";
    }
  },
  vanContactCard: {
    addText: 'เพิ่มผู้ติดต่อ'
  },
  vanContactList: {
    addText: 'ผู้ติดต่อใหม่'
  },
  vanPagination: {
    prev: 'ย้อนกลับ',
    next: 'ถัดไป'
  },
  vanPullRefresh: {
    pulling: 'ดึงลงเพื่อรีเฟรช...',
    loosing: 'ปล่อยเพื่อรีเฟรช...'
  },
  vanSubmitBar: {
    label: 'รวม：'
  },
  vanCoupon: {
    unlimited: 'ไม่จำกัด',
    discount: function discount(_discount) {
      return _discount + "\u6298";
    },
    condition: function condition(_condition) {
      return "\u6EE1" + _condition + "\u5143\u53EF\u7528";
    }
  },
  vanCouponCell: {
    title: 'คูปอง',
    tips: 'ยังไม่พร้อมใช้งาน',
    count: function count(_count) {
      return _count + "\u5F20\u53EF\u7528";
    }
  },
  vanCouponList: {
    empty: 'ไม่มีคูปองชั่วคราว',
    exchange: 'แลกเปลี่ยน',
    close: 'ไม่สามารถใช้คูปองได้',
    enable: 'สามารถใช้ได้',
    disabled: 'ไม่สามารใช้ได้',
    placeholder: 'กรุณากรอกรหัสส่วนลด'
  },
  vanAddressEdit: {
    area: 'ที่อยู่',
    postal: 'รหัสไปรษณีย์',
    areaEmpty: 'กรุณาเลือกพื้นที่',
    addressEmpty: 'กรุณากรอกที่อยู่โดยละเอียด',
    postalEmpty: 'รูปแบบรหัสไปรษณีย์ไม่ถูกต้อง',
    defaultAddress: 'ตั้งเป็นที่อยู่จัดส่งเริ่มต้น',
    telPlaceholder: 'หมายเลขโทรศัพท์มือถือของผู้รับ',
    namePlaceholder: 'ชื่อผู้รับ',
    areaPlaceholder: 'เลือกตำบล / อำเภอ / จังหวัด'
  },
  vanAddressEditDetail: {
    label: 'ที่อยู่โดยละเอียด',
    placeholder: 'เลขที่บ้านเลขที่ห้องชั้นและข้อมูลอื่น ๆ'
  },
  vanAddressList: {
    add: 'เพิ่มที่อยู่'
  }
};
exports.default = _default;