<template>
    <div class="auction-list fadeIn" @scroll="scrollEvent" ref="scrollDiv" >
        <h4 class="list-title">
            {{item.title}}
        </h4>
        <!--  新手体验区  -->
        <div class="list-item"    v-for="(l,i) in list" :key="i" @click="gotoGoodDetail(l)" v-if="l.is_try==isnew?1:0">
            <template >
                <!-- <img class="item-img" alt="" v-lazy="l.thumb" > -->
                <div class="item-img">
                    <img class="new-img" alt="" :src="l.thumb" >
                </div>
                <div class="item-right">
                    <div class="item-right-one">
                        <span>{{l.qishu_trans}}</span>
                        <span v-if="cb">${{(l.total_amount?l.total_amount:'0') | number_format}}</span>
                    </div>
                    <div v-if="tt" class="item-right-price">
                        <span>{{$t('common.now_price')}}</span>
                        <span>${{(l.now_price?l.now_price:'0') | number_format}}</span>
                    </div>
                    <h2 class="item-right-name van-multi-ellipsis--l2">{{l.title}}  </h2>
                    <template v-if="cb">
                        <div  class="item-right-process" v-if="l.total_need">
                            <van-progress v-if="cb&&l.active_id==1" :percentage="parseInt((l.join_num/l.total_need)*100)" color="#DF561C" :show-pivot="false"/>
                        </div>
                        <div  class="item-right-total">
                            <p>{{$t('home.has_enter')}}</p>
                            <p>
                                <span class="color-main">{{l.join_num}}</span>
                                <span>/</span>
                                <span>{{l.total_need}}</span>
                            </p>
                        </div>
                        <!-- <div class="item-wait " v-if="l.resultOut">
                            <p class="p-result p-small-box" >{{$t('add.ended')}}</p>
                        </div> -->
                    </template>
                   
                    <div v-if="tt" class="item-right-last">
                        <template v-if="parseInt(l.day || 0) * 24 * 3600 * 1000 + parseInt(l.hours ||  0)  * 3600 * 1000 + parseInt(l.mins||  0)  *60 * 1000 + parseInt(l.second ||  0)  * 1000>0&&!l.timeend">
                            <img class="item-lou" :src="require('@/assets/gif/lou.gif')" alt="">
                            <van-count-down @finish="timeendaction(l)"  :key="l.countKey"  millisecond  format="HH:mm:ss:SS" :time="parseInt(l.day || 0) * 24 * 3600 * 1000 + parseInt(l.hours ||  0)  * 3600 * 1000 + parseInt(l.mins||  0)  *60 * 1000 + parseInt(l.second ||  0)  * 1000" class="item-end" >
                            </van-count-down>
                        </template>
                        <div class="item-wait" v-else>
                            <p class="p-end p-small" v-if="!l.resultOut&&l.is_end!='1'" ><van-loading class="p-icon" color="#DF561C" /> {{$t('add.wait_for_result')}}</p>
                            <p class="p-result p-small" v-else >{{$t('add.ended')}}</p>
                        </div>
                    </div>

                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        cb:Boolean,
        tt:Boolean,
        item:Object,
        list:Array,
        isnew:Boolean,
        timeendaction:Function,
        scrolltop:Number
    },
  data(){
    return {
       imgSrc:"http://47.104.209.44/base/img/5.jpg"
    }
  },
  mounted(){
    //   console.log(this.scrolltop)
      this.$refs.scrollDiv.scrollTop = this.scrolltop;
  },
  methods:{
      scrollEvent(){
        //   console.log(this.$refs.scrollDiv.scrollTop);
        
      },
      gotoGoodDetail(item){
          this.$router.push({name:this.$route.query.isnew=="1"?'mine-trygood':'goods',query:{
              gid:item.good_id,
              qishu:item.qishu,
              type:this.cb?'cb':'tt',
              isend:0,  // 没有结束
              isnew:this.$route.query.isnew=="1"?"1":"0",
              scrolltop:this.$refs.scrollDiv.scrollTop
          }})
      }
  }
}
</script>