export default [{
    path: "/404",
    name: "404",
    component: () =>
        import ("@/views/errorpage/404.vue")
},
{
    path: "/500",
    name: "500",
    component: () =>
        import ("@/views/errorpage/500.vue")
},

{
    path: "/503",
    name: "503",
    component: () =>
        import ("@/views/errorpage/503.vue")
},

{
    path: "/403",
    name: "403",
    component: () =>
        import ("@/views/errorpage/403.vue")
},

{
    path: "/systemerror",
    name: "systemerror",
    component: () =>
        import ("@/views/errorpage/systemerror.vue")
},

];