<template>
    <el-row v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.5)"  :element-loading-text="$t('signin.loading')">
        <slot></slot>
    </el-row>
</template>

<script>
export default {
    props:['loading']
}
</script>
