<template>
	<section class="y-calendar">
		<!--日历头部-->
		<aside class="y-calendar-head">
			<div class="y-calendar-item left"
					 :class="{'y-disable': currentMonth <= 1}"
					 @click="handleLeftClick">
				<!-- <span v-if="!$slots.headLeft">←</span> -->
                <van-icon v-if="!$slots.headLeft" name="arrow-left" class="y-icon"></van-icon>
				<slot name="headLeft"></slot>
			</div>
			<div class="y-calendar-item" @click="showtime=true">
				{{year}}/{{month}}
			</div>
			<div class="y-calendar-item right"
					 :class="{'y-disable': currentMonth >= 12}"
					 @click="handleRightClick">
				<!-- <span v-if="!$slots.headRight">→</span> -->
          <van-icon v-if="!$slots.headRight" name="arrow" class="y-icon"></van-icon>
				<slot name="headRight"></slot>
			</div>
		</aside>
		<div class="striping" v-if="striping"></div>
		<!--日历数据-->
		<section class="y-calendar-content">
			<div class="y-calendar-content-head">
				<div class="y-head-item" v-for="(item,index) in weekDate" :key="index">{{item}}</div>
			</div>

			<div class="y-calendar-content-list">
				<div class="y-list-item"
						 v-for="(item,index) in currentDay" :key="index"
						 @click="handleChangeItem(item)">
					<p class="y-day"
						 v-if="!circle" 
						 
             :class="itemClass(item)"
						 :style="circle ? '' :setDayStyle(item.day)"
                         @click="clickDay(item.day)"
					>
						<span>{{item.day}}</span>
					</p>
					<p class="y-day"
						 :class="[cDay == item.day ? 'y-day--active' :'']"
						 :style="cDay == item.day ? `border:1px solid ${borderColor};`:''"
						 v-else>
						<span>{{item.day}}</span>
						<i v-if="showCircle(item.day)" :style="`background:${backgroundColor};`"></i>
					</p>
				</div>
			</div>
			<div class="y-remark">
				<p>{{setRemark}}</p>
			</div>
		</section>
     <!-- 补签-->
        <van-overlay class="g-overlay" :show="repay" >
          <div class="repay-mask" @click.stop>
                <div class="top">
                    {{$t('mine.qiandao')}}
                </div>
                <div class="title">{{$t('qian.sure_sign')}}</div>
                <p class="ptext">{{$t('qian.ke_hua')}} <span>1</span>  Bid Token {{$t('qian.ke_buqian')}}</p>
                <div class="overlay-btns">
                    <van-button class="btn btn-cancel" @click="repay=false" >{{$t('mine.no')}}</van-button>
                    <van-button class="btn btn-sure" @click="buqianAction">{{$t('mine.yes')}}</van-button>
                </div>
          </div>
        </van-overlay>

             <!-- 选择年月 -->
          <van-popup v-model="showtime" class="time-popup" position="bottom"   duration="0.2" >
              <van-datetime-picker
                  v-model="currentDate"
                  type="year-month"
                  class="time-picker"
                  @cancel="showtime=false"
                  @confirm="chooseDate"
                  :formatter="formatter"
              />
          </van-popup>
	</section>
</template>


<script>
  import { uppercase } from '.'
  import {Toast,Dialog,Notify} from "vant"
  export default {
    components : {},
    mixins : [],
    name : "Calendar",
    data () {
      return {
        showtime:false,
        currentDate: new Date(),
        repay:false,
        year : new Date().getFullYear(),
        currentMonth : 1, //当前月
        month : '', //月份
        weekDate : [ '日', '一', '二', '三', '四', '五', '六' ],
        lang:localStorage.getItem("langData") || 'th',
        cDay : 1,
        cDayStyle : [],  //选中样式
        currentDay : [], //当前月天数
        currentRemark : 0,
        qianday:'',
      }
    },
    props : {
      qianDaoinToday:Function,
      getQingDaoData:Function,
      list:Array,
      //选中是否采用圆点模式
      circle : Boolean,
      //是否显示横线
      striping : {
        type : Boolean,
        default : true
      },
      //样式
      borderColor : {
        type : String,
        default : '#37b48d'
      },
      backgroundColor : {
        type : String,
        default : '#37b48d'
      },
      dayActive : {
        type : Array,
        default : () => []
      }
    },
    computed : {
      
      setRemark () {
        if ( this.cDayStyle.length > 0 ) {
          for ( let i = 0 ; i < this.cDayStyle.length ; i++ ) {
            const cDayStyleElement = this.cDayStyle[ i ];
            if ( cDayStyleElement.active == this.currentRemark ) {
              return cDayStyleElement.remark ? cDayStyleElement.remark : ''
            }
          }
        }
      }
    },
    watch : {
      dayActive : {
        handler ( day ) {
          if ( day.length > 0 && Array.isArray(day) ) {
            this.cDayStyle = day
          } else {
            this.cDayStyle = []
          }
        },
        deep : true,
        immediate : true
      }
    },
    methods : {
      chooseDate(value){
            this.showtime = false;
            this.year = new Date(value).getFullYear();
            this.month = new Date(value).getMonth() + 1;
            // this.month = this.month < 10 ? '0'+this.month : this.month;
            this.currentMonth = this.month;
            this.initData(this.currentMonth,this.year);
            let eMsg = { year : this.year, month : this.currentMonth }
            // this.$emit('change', eMsg)
        },
        formatter(type, val) {
          if (type === 'year') {
              return `${val}`;
          } else if (type === 'month') {
              return `${val}`;
          }
          return val;
      },
      buqianAction(){
        console.log(this.qianday,this.year,this.currentMonth)
        // 补签操作 
        this.$ajax.signupwithMoney({
          sign_date:this.year+'-'+this.currentMonth+'-'+this.qianday
        }).then(res=>{
          if(res.code==200){
            Notify({ type: 'success', message: res.msg });
            this.repay = false;
            this.getQingDaoData();
          }
        })
      },
      itemClass(item){
        // 已签到   
        // 未签到   日期小于今天  
        // 今日已经签到
        // 今日未签到  
        // console.log(item.day);
        // console.log(item.is_qian);
        var day = new Date().getDate();   // 今日 几号  
        var thisClass = []
        var month = new Date().getMonth()+1;
        var year = new Date().getFullYear();
        if(day==item.day&&month==this.month&&this.year==year){
          thisClass  = [ item.today ? 'has_qian_today' : 'has_no_qian_today']
        }else{
          thisClass  = [ item.is_qian  ? 'has_qian' : 'has_no_qian']
        }
        return thisClass
      },
      clickDay(item){ 

      },
      initData ( month,year ) {
        this.month = month;
        this.monthDay(month,year)
      },
      //当前月天数， 当前月第一天是星期几
      monthDay ( month,year ) {
				this.year = year ? year :this.year;
        let months = month.toString().length == 1 ? '0' + (month) : month
				
				let dayList = new Date(this.year, months, 0).getDate();
        this.currentDay = [];
        for ( let i = 0 ; i < dayList ; i++ ) {
          this.currentDay.push({day:i + 1})
        }
          var day = new Date().getDate();
          this.currentDay.forEach(item=>{
            this.list.forEach(val=>{

              // let date = new Date(val.create_time);
              // console.log(val.create_time)
              // let time = date.getFullYear() + "/"+(date.getMonth()*1+1)+"/"+date.getDate();
              // 后台传递的数据 问题  
              let time = val.create_time.toString().split("")
              time.splice(4,0,"/")
              time.splice(7,0,"/")
              time = time.join("");
              if(new Date(this.year+"/"+months+"/"+item.day).getTime()==new Date(time).getTime()){
                  item.is_qian  =true;
                  // 签到的时间 == 今天 

                  if(day==new Date(time).getDate()){
                    item.today = true;
                  }
              }
            })
          })
          // console.log(this.currentDay)
        
        //当前是几号
        if ( new Date().getMonth() + 1 == month ) {
          this.cDay = new Date().getDate();
          this.currentRemark = this.cDay
        } else {
          this.cDay = 0
        }
        //计算上月最后一天是星期几
        this.$nextTick(() => {
          let day = new Date(this.year, month - 1).getDay();
          // console.log(day);
          let itemEl = document.getElementsByClassName('y-list-item')[ 0 ]
          itemEl.style = `margin-left:${day * 14.28 + '%'};`
        })
        let eMsg = { year : this.year, month : this.currentMonth }
        this.$emit('change', eMsg)
      },
      //点击前一月
      handleLeftClick () {
        if ( this.currentMonth <= 1 ) {
          this.year--;
          this.currentMonth = 12 ;
        }else{
          this.currentMonth--;
        }
        this.initData(this.currentMonth,this.year);
        let eMsg = { year : this.year, month : this.currentMonth }
        this.$emit('change', eMsg)
      },
      //点击后一月
      handleRightClick () {
        if ( this.currentMonth >= 12 ) {
           this.year++;
            this.currentMonth = 1 ;
        }else{
          this.currentMonth++;
        }
        this.initData(this.currentMonth,this.year);
        let eMsg = { year : this.year, month : this.currentMonth }
        this.$emit('change', eMsg)
      },
      //样式
      setDayStyle ( item ) {
        for ( let i = 0 ; i < this.cDayStyle.length ; i++ ) {
          const cDayStyleElement = this.cDayStyle[ i ];
          if ( cDayStyleElement.active == item ) {
            return `
							color: #ffffff;
							box-sizing: border-box;
							border-radius: 50%;
							border: 1px solid ${this.borderColor}!important;
							background: ${this.backgroundColor};
							transition: all .3s ease-in-out;
						`
          }
        }
      },
      //圆点显示
      showCircle ( item ) {
        for ( let i = 0 ; i < this.cDayStyle.length ; i++ ) {
          const cDayStyleElement = this.cDayStyle[ i ];
          if ( cDayStyleElement.active == item ) {
            return true
          }
        }
      },
      //点击日历
      handleChangeItem ( item ) {
        // console.log(this.year+"/"+this.month+"/"+item.day)
        var clickTime = new Date(new Date(this.year+"/"+this.month+"/"+item.day).toLocaleDateString()).getTime();
        var today = new Date();
        var todayTime =  new Date(new Date().toLocaleDateString()).getTime();

        // var todayTime =  new Date(today.getFullYear()+'/'+today.getMonth()+1+'/'+today.getDate()).getTime();
        var month = new Date().getMonth() + 1;

        // console.log(this.year+'/'+this.month+'/'+item.day);
        // console.log(clickTime)
        // console.log(today.getFullYear()+'/'+(today.getMonth()+1)+'/'+today.getDate());
        // console.log(todayTime)

        if(clickTime==todayTime){
          if(item.is_qian){
              Toast(this.$t('qian.today_sign'))
            }else{
              this.qianDaoinToday();
          }
        }else if(clickTime>todayTime){
          Toast(this.$t('qian.ming_sign'))
        }else if(clickTime<todayTime){
            if(item.is_qian){
              Toast(this.$t('qian.today_has_sign'))
            }else{
              // 补签 
              this.repay = true;
              this.qianday = item.day;
            }
        }
        // if(this.currentMonth==month){
        //   if(this.cDay==item.day){
        //     if(item.is_qian){
        //       Toast(this.$t('qian.today_sign'))
        //     }else{
        //       this.qianDaoinToday();
        //     }
        //   }else if(this.cDay<item.day){
        //     Toast(this.$t('qian.ming_sign'))
        //   }else if(this.cDay>item.day){
        //     if(item.is_qian){
        //       Toast(this.$t('qian.today_has_sign'))
        //     }else{
        //       // 补签 
        //       this.repay = true;
        //       this.qianday = item.day;
        //     }
        //   }
        // }else{
        //     if(month>this.currentMonth){
        //        Toast(this.$t('qian.today_has_sign'))
        //     }else{
        //        Toast(this.$t('qian.ming_sign'))
        //     }
        // }
        this.currentRemark = item;
      }
    },
    mounted () {
    },
    created () {
      this.currentMonth = new Date().getMonth() + 1;
      this.initData(this.currentMonth);

      console.log(this.lang)
      if(this.lang!="cn"){
        this.weekDate = ['Su','Mo','Tu','We','Th','Fr','Sa']
      }else{
         this.weekDate =  [ '日', '一', '二', '三', '四', '五', '六' ]
      }
    },
    filters : {},
    directives : {},
    beforeDestroy () {
    },
    destroyed () {
    }
  }

//   :class="[cDay == item ? 'has_qian' :'']"
</script>

