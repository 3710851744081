<template>
<div class="fixed">
    <footer class="footer">
        <div v-for="(item,i) in NavList" :key="i">
            <router-link :to="{name:item.name}" >
                <i v-if="i!==2" class="iconfont" :class="item.icon"></i>
                <span v-if="i!==2">{{$t(item.title)}}</span>
                <div v-if="i==2" class="tab-main">
                    <img class="logo" src="@/assets/gif/icon.gif" alt="">
                </div>
            </router-link>
        </div>
    </footer>
</div>
    
</template>


<script>

export default {
    name:"myfoot",
    data(){
        return {
            NavList:[
                {txt:this.$t('common.dashboard'),path:"/main/home",icon:"iconshouye",name:"main-homeIndex",title:'common.dashboard'},
                {txt:this.$t('common.jiexiao'),path:"/main/result",icon:"iconjiexiao",name:"main-resultShai",title:'common.jiexiao'},
                {txt:this.$t('common.dashboard'),path:"/main/auction",icon:"",name:"main-auctionIndex",title:''},
                {txt:this.$t('common.wallet'),path:"/main/wallet",icon:"iconqianbao",name:"main-walletIndex",title:'common.wallet'},
                {txt:this.$t('common.mine'),path:"/main/mine",icon:"iconwode",name:"main-mineIndex",title:'common.mine'},
            ]
        }
    }
}
</script>
