
<template>
  <div class="cb-list" >
    <template v-if="items.length>0">
      <MyList  :listadd="listadd" ref="mylist" :refreshajax="refresh" >
      <div class="cb-item" v-for="(l,i) in items" :key="i">
        <div class="cb-item-top">
          <div class="top-one">
            <img class="item-img" alt="" v-lazy="l.thumb">
            <div class="item-right">
              <div class="item-right-one">
                <span>{{l.qishu_trans}} </span>
                <span v-if="cb">${{l.total_amount | number_format}}</span>
              </div>
              <h2 class="item-right-name van-multi-ellipsis--l2">{{l.title}}</h2>
              <div class="item-right-time">
                <p>{{$t('common.open_time')}}</p>
                <p class="time-detail">
                  {{l.starttime || l.winning_time}}
                </p>
              </div>
            </div>
          </div>
          <div class="top-common top-shu" v-if="cb">
            <div class="top-func">
              {{$t('common.open_func')}}: {{l.winningFormula}}
            </div>
            <div class="top-my">
              <!-- v-if="l.isWinner==1" -->
              <div >
                {{$t('common.my_auction')}} <span class="my-btn" @click="gotoGoodDetail(l)">{{$t('common.seen')}}</span>
              </div>
              <!-- <div class="top-right" @click="gotoShaidan(l)" v-if="l.isWinner==1">
                <img src="../../../assets/images/shai2.png" class="shai-icon" alt="">
                <span class="top-right-shai">{{$t('home.goshai')}}</span>
              </div> -->
            </div>
          </div>
          <div class="top-common" v-if="tt">
            <div class="top-func">
              {{$t('common.good_value')}}: <span>${{l.total_amount | number_format}}</span>
            </div>
            <!-- <div class="top-right" @click="gotoShaidan(l)" v-if="l.isWinner==1">
              <template>
                <img src="../../../assets/images/shai2.png" class="shai-icon" alt="">
                <span class="top-right-shai">{{$t('home.goshai')}}</span>
              </template>
              <span class="top-right-shai">{{'已晒单'}}</span>
            </div> -->
          </div>

        </div>
        <div class="cb-item-hot">
          <div class="hot-left">
            <span>{{$t('common.lucker')}}:</span>
            <span>{{l.winner_id | yinfour}}</span>
          </div>
          <div v-if="cb">
            <span>{{$t('common.jiang_code')}}:</span>
            <span>{{l.lucky_num}}</span>
          </div>
          <div v-if="tt">
            <span>{{$t('common.luck_price')}}:</span>
            <span>${{l.betprice | number_format}}</span>
          </div>
        </div>

      </div>
      </MyList>
    </template>

    <div v-if="items.length==0" class="no-box">
      <img class="juan-no" src="../../../assets/images/xuqiu-no.png" alt="">
      <p class="no-text">{{$t('good.no_data')}}</p>
    </div>
  </div>
</template>


<script>
// 揭晓数据 
export default {
  props: {
    cb: Boolean,
    tt: Boolean,
    items: Array,
    refresh:Function,
    listadd:Function
  },
  data() {
    return {
      imgSrc: require("@/assets/images/img-shou.png"),
    };
  },
  methods: {
    gotoGoodDetail(item) {
      // this.$router.push({name:'goods',query:{
      //     type:this.cb?'cb':'tt',
      //     isend:1  // 已经结束
      // }})

      this.$router.push({
        name: "goods",
        query: {
          type: this.cb ? "cb" : "tt",
          isend: 1, // 已经结束
          gid: item.good_id,
          qishu: item.qishu,
        },
      });

      // this.$router.push({ name: "mine-myAuction", query: {} });
    },
    gotoShaidan(item) {
      this.$router.push({
        name: "result-edit",
        query: {
          gid: item.good_id,
          qishu: item.qishu,
          orderid: item.winning_orderid,
          betprice: item.betprice,
          time: item.winningTime || item.winning_time,
          value: item.product_value,
        },
      });
    },
  },
};
</script>