
// 防抖 
/**
* @desc 函数防抖
* @param func 目标函数
* @param wait 延迟执行毫秒数
*/
export function debounce(func, wait) {
    let timeout = null
    return function () {
        let context = this
        let args = arguments
        if (timeout) clearTimeout(timeout)
        timeout = setTimeout(() => {
            func.apply(context, args)
        }, wait)
    }
}

/*
生成 64 位的随机字符串 
*/ 
export function randomString(len) {
　　len = len || 64;
　　var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';    /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
　　var maxPos = $chars.length;
　　var str = '';
　　for (let i = 0; i < len; i++) {
        str += $chars.charAt(Math.floor(Math.random() * maxPos));
    }
　  return str;
}

/*
金主组合类别
*/ 

export const package_types = [
    {
        name:'付费',
        value:1,
        label:"pins.bv",
    },
    {
        name:'免费',
        value:0,
        label:"pins.non_bv",
    }
]

export const number_format = value=>{
    return parseFloat(value).toLocaleString('en', {minimumFractionDigits: 2});
}


export const dateFormat = (value,format)=>{
    if(!value) return value;
    return moment(value).format(format)
}

// 默认的国家选择  
export const areaCode = {
    area:'66',
    // country_name:'China (中国)',
    country_name:"Thailand (ไทย)"
    // enname:"cn"
}

/*
状态 
*/ 
export const is_actives = [
    {
        name:'已激活',
        value:"1",
        label:"pins.active",
    },
    {
        name:'未激活',
        value:"-1",
        label:"pins.no_active",
    }
]

/*
代码状态 
*/ 
export const is_usefuls = [
    {
        name:'可用',
        value:'1',
        label:"pins.is_use",
    },
    {
        name:'不可用',
        value:'-1',
        label:"pins.is_not_use",
    }
]

import {mapState,mapMutations,mapGetters} from "vuex"
// 用户信息数据的 混入
export const myinfo = {
    computed:{
        ...mapState(['dashboard']),
        ...mapGetters(['user']),
        tgold(){  //体验金
            return this.user.walletlist.find(item=>item.current_id==1034)
        }
    },
    methods:{
        ...mapMutations(['setdashboard','setCardList']),
        gotowhere(name){ 
            this.$router.push({
                name
            })
        }
    },
    mounted(){
         // 用户详情  存在就不获取  不存在就重新请求数据  
        if(!this.dashboard.username){
            this.$ajax.dashboard()
            .then(res=>{
                this.setdashboard(res.data);
                localStorage.setItem('dashboard',JSON.stringify(res.data))
            })
        }
    }
}


export const lang = localStorage.getItem("langData") || 'th'