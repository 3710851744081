import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store"
import Logins from '@/views/logins/routes';
import Wallets from '@/views/wallet/routes';
import Errors from "@/views/errorpage/router"
import Usdts from '@/views/wallet/usdt/route';
Vue.use(VueRouter)

const routes = [

    {
        path: "/",
        name: "",
        redirect: "/guide",
    },
    {
        path: "/guide",
        name: "guide",
        component: () =>
            import ("@/views/guide/index.vue")
    },
    {
        path: "/main",
        name: "main",
        component: () =>
            import ("@/views/main/index.vue"),
        redirect: "/main/home",
        children: [{
                path: "home",
                name: "main-homeIndex",
                component: () =>
                    import ("@/views/main/homeIndex.vue")
            },
            {
                path: "result",
                name: "main-resultShai",
                component: () =>
                    import ("@/views/main/resultShai.vue"),
                meta: {
                    keepAlive: true		//缓存
                }
            },
            {
                path: "auction",
                name: "main-auctionIndex",
                component: () =>
                    import ("@/views/main/auctionIndex.vue")
            },
            {
                path: "wallet",
                name: "main-walletIndex",
                component: () =>
                    import ("@/views/main/walletIndex.vue")
            },
            // 我的页面
            {
                path: "mine",
                name: "main-mineIndex",
                component: () =>
                    import ("@/views/main/mineIndex.vue")
            },
        ]
    },
    {
        path: "/goods",
        name: "goods",
        meta: {
            keepAlive: true, //该字段表示该页面需要缓存
            isBack: false, //用于判断上一个页面是哪个 
        },
        component: () =>
            import ("@/views/auction/goods.vue")
    },
    {
        path: "/myAuction",
        name: "mine-myAuction",
        component: () =>
            import ("@/views/mine/myAuction.vue")
    },
    {
        path: "/myjuan",
        name: "mine-juan",
        component: () =>
            import ("@/views/mine/myjuan.vue")
    },
    {
        path: "/myqrcode",
        name: "mine-qrcode",
        component: () =>
            import ("@/views/mine/mycode.vue")
    },
    {
        path: "/myneed",
        name: "mine-need",
        component: () =>
            import ("@/views/mine/xuqiu/add.vue")
    },
    {
        path: "/myxuqiulist",
        name: "mine-xuqiulist",
        component: () =>
            import ("@/views/mine/xuqiu/list.vue")
    },
    {
        path: "/myxuqiudetail",
        name: "mine-xuqiudetail",
        component: () =>
            import ("@/views/mine/xuqiu/detail.vue")
    },
    {
        path: "/myscore",
        name: "mine-score",
        component: () =>
            import ("@/views/mine/score/index.vue")
    },
    {
        path: "/mybang",
        name: "mine-bang",
        component: () =>
            import ("@/views/mine/bang/index.vue")
    },
    {
        path: "/myteam",
        name: "mine-team",
        component: () =>
            import ("@/views/mine/team/index.vue")
    },
    {
        path: "/myjiang",
        name: "mine-jiang",
        component: () =>
            import ("@/views/mine/jiang/index.vue")
    },
    {
        path: "/mychoucode",
        name: "mine-code",
        component: () =>
            import ("@/views/mine/mycodelist.vue")
    },
    {
        path: "/myhelp",
        name: "mine-help",
        component: () =>
            import ("@/views/mine/help/index.vue")
    },
    {
        path: "/myhelpdetail",
        name: "mine-help-detail",
        component: () =>
            import ("@/views/mine/help/detail.vue")
    },
    {
        path: "/myinfo",
        name: "mine-info",
        component: () =>
            import ("@/views/mine/info/index.vue")
    },
    {
        path: "/nickname",
        name: "mine-nickname",
        component: () =>
            import ("@/views/mine/info/nickname.vue")
    },
    {
        path: "/myemail",
        name: "mine-email",
        component: () =>
            import ("@/views/mine/info/email.vue")
    },
    {
        path: "/mypassword",
        name: "mine-pass",
        component: () =>
            import ("@/views/mine/info/loginpass.vue")
    },
    {
        path: "/mysecpass",
        name: "mine-secondpass",
        component: () =>
            import ("@/views/mine/info/secondpass.vue")
    },
    {
        path: "/resetpinpass",
        name: "mine-resetpinpass",
        component: () =>
            import ("@/views/mine/info/resetpinpass.vue")
    },
    {
        path: "/resetpindone",
        name: "mine-resetpindone",
        component: () =>
            import ("@/views/mine/info/resetpindone.vue")
    },
    {
        path: "/myaddress",
        name: "mine-address",
        component: () =>
            import ("@/views/mine/info/address.vue")
    },
    {
        path: "/address/add",
        name: "mine-address-add",
        component: () =>
            import ("@/views/mine/info/add_address.vue")
    },
    {
        path: "/address/reset",
        name: "mine-address-reset",
        component: () =>
            import ("@/views/mine/info/reset_address.vue")
    },
    {
        path: "/setphone",
        name: "mine-phone-reset",
        component: () =>
            import ("@/views/mine/info/set_phone.vue")
    },
    {
        path: "/realname",
        name: "mine-realname",
        component: () =>
            import ("@/views/mine/info/realname.vue")
    },
    {
        path: "/repay",
        name: "huishou-repay",
        component: () =>
            import ("@/views/mine/repay.vue")
    },
    {
        path: "/qiandao",
        name: "mine-qiandao",
        component: () =>
            import ("@/views/mine/qian/index.vue")
    },
    {
        path: "/card",
        name: "mine-card",
        component: () =>
            import ("@/views/mine/info/card.vue")
    },
    {
        path: "/mycard-add",
        name: "mine-card-add",
        component: () =>
            import ("@/views/mine/info/add_card.vue")
    },
    {
        path: "/mycard-set",
        name: "mine-card-set",
        component: () =>
            import ("@/views/mine/info/change_card.vue")
    },
    {
        path: "/newtry",
        name: "mine-newtry",
        component: () =>
            // import ("@/views/mine/news/index.vue")
            import ("@/views/mine/news/index.vue")
    },
    {
        path: "/trygood",
        name: "mine-trygood",
        component: () =>
            // import ("@/views/mine/news/index.vue")
            import ("@/views/mine/news/good.vue")
    },
    {
        path: "/viewinfo",
        name: "mine-viewinfo",
        component: () =>
            // import ("@/views/mine/news/index.vue")
            import ("@/views/mine/mybet/info.vue")
    },
    {
        path: "/home-gonggao",
        name: "home-gonggao",
        component: () =>
            import ("@/views/home/gonggao.vue")
    },
    {
        path: "/home-gaodetail",
        name: "home-gaodetail",
        component: () =>
            import ("@/views/home/gao_detail.vue")
    },
    {
        path: "/home-market",
        name: "home-market",
        component: () =>
            import ("@/views/home/market.vue")
    },

    {
        path: "/jiexiao",
        name: "jiexiao",
        // component:()=>import("@/views/result/index.vue")
        component: () =>
            import ("@/views/result/index.vue")
    },
    {
        path: "/result-edit",
        name: "result-edit",
        component: () =>
            import ("@/views/result/edit.vue")
    },
    ...Logins,
    ...Wallets,
    ...Errors,
    ...Usdts,
    {
        path: "*",
        redirect: {
            name: "main-homeIndex"
        }
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        // console.log(from)
        // if (savedPosition) {
        //     return savedPosition
        // } else {
        //     return { x: 0, y: 0 }
        // }
    },
})

// 全局路由守卫
router.beforeEach((to, from, next) => {

    // 没有登录的话  点击需要进入登录 
        var namesRoute = ['mine', 'wallet'] // 需要判断是否登录的Tab 
        var access_token = localStorage.getItem("access_token");
        // if (isLogin) {
        //     next();
        // } else {
        //     if ((namesRoute.indexOf(to.name) >= 0)) {
        //         // console.log("dsadssad")
        //         // store.commit("changeLoginShow",true); 
        //         next();
        //         // 显示 Login 系列组件 
        //     } else {
        //         next();
        //     }
        // }

        if (typeof to.query._t !== "undefined") {
            next();
        } else {
            to.query._t = new Date().getTime().toString();
            next(to);
        }

})

router.afterEach((to, from) => {
    // console.log("afterEach")
    // store.commit('setgoForward',true);
})

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}


// VueRouter.prototype.push = function push(location, onResolve, onReject) {
// 	if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
// 	return originalPush.call(this, location).catch(err => err)
// }

export default router