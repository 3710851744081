<template>
    <div class="swiper-container"  :id="id"   >
        <div class="swiper-wrapper">
            <slot></slot>
        </div>
    </div>
</template>


<script>
import Swiper from "swiper"
import "swiper/dist/css/swiper.min.css"
var  mySwiper  = null;
export default {
    name:"myswiper",
    props:['id','swiperoptions'],
    mounted(){
        this.$nextTick(()=>{ 
            mySwiper  = new Swiper('#'+this.id,this.swiperoptions)
        })
    },
    methods:{
        slideTo(index){
            mySwiper.slideTo(index, 1000, false);//切换到第一个slide，速度为1秒
        }
    }
}
</script>