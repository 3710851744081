<template>
<div class="tt-box">
  <div class="content-one" >
        <van-swipe
            vertical
            class="notice-swipe"
            id="notice-swipe"
            :autoplay="3000"
            :show-indicators="false"
            v-if="items.length>0"
        >
            <van-swipe-item class=""  v-for="(l,i) in items.filter(item=>item.is_perfect==1)" :key="i"  @click="gotoShaiHeight('ttitem'+l.id)">
                   <div class="left ">
                      <img class="left-img" v-lazy="l.winner_headimgurl" alt="">
                      <span class="left-name color-gard">{{l.winnerUid  }} </span>
                      <p class="left-title van-ellipsis" v-if="l.product">{{$t('add.get')}} {{l.product.title}}</p>
                  </div>
                  <div class="right" >{{$t('add.good20')}}</div>
            </van-swipe-item>
        </van-swipe>
    </div>
    <div class="tt-list">
    <div class="tt-sort" v-if="items.length>0">
      <span>{{$t('home.ai_sort')}}</span>
      <van-popover v-model="showTime" trigger="click" :actions="actions" @select="onSelectTime" class="tt-pop">
        <template #reference>
          <p>
            <span class="tt-title">{{$t('home.time')}}</span>
            <img v-if="time==-1" class="tt-arrow" src="../../../assets/imgs/arrow-down.png" alt="">
            <img v-if="time==1" class="tt-up" src="../../../assets/imgs/arrow-down.png" alt="">
          </p>
        </template>
      </van-popover>

      <van-popover v-model="showLike" trigger="click" :actions="actions" @select="onSelectLike">
        <template #reference>
          <p>
            <span class="tt-title">{{$t('home.like')}}</span>
            <img v-if="like==-1" class="tt-arrow" src="../../../assets/imgs/arrow-down.png" alt="">
            <img v-if="like==1" class="tt-up" src="../../../assets/imgs/arrow-down.png" alt="">
          </p>
        </template>
      </van-popover>
    </div>
    <div v-if="items.length>0">
      <div class="tt-item" v-for="(l,i) in items" :key="i"  :id="'ttitem'+l.id">
        <div class="top-two">
          <div class="userinfo">
            <img class="user-avatar" alt="" :src="$host+l.thumb">
            <span class="user-name">{{l.user_uid | yinfour}}</span>
          </div>
           
          <div class="top-time">
            <img v-if="l.is_perfect!==0" :src="require('../../../assets/imgs/'+lang+'_pin.png')" class="top-apin" alt="">
            {{l.from_now_time}}
           
          </div>
        </div>

        <div class="top-one" v-if="l.product">
          <img class="item-img" alt=""  :src="l.product.thumb">
          <div class="item-right">
            <div class="item-right-one"> {{l.product.qishu_trans}} </div>
            <h2 class="item-right-name van-multi-ellipsis--l2">{{l.title || l.product.title}}   </h2>
            <div class="item-right-time">
              <p>{{$t('common.open_time')}} <span>{{l.winningTime}}</span></p>
            </div>
            <div class="item-right-value">
              <p v-if="!(cb)">{{$t('common.good_value')}}:<span>${{l.total_amount | number_format }}</span></p>
              <p v-if="!(cb)">{{$t('common.auction_price')}}:<span>${{l.betprice  | number_format }}</span></p>
              <p v-if="cb">{{$t('common.jiang_code')}}:<span>{{l.lucky_num}}</span> </p>
              <p v-if="cb">{{$t('common.good_value')}}:<span>${{l.total_amount | number_format }}</span></p>
            </div>
          </div>
        </div>

        <div class="top-word ">
          {{l.content}}
         
        </div>
        <div class="img-list" v-if="l.show_pic[0]">
          <img v-for="(v,g) in l.show_pic" :key="g" @click="showBigImg(l.show_pic,g)" class="img-detail" alt="" :src="v">
        </div>
        <div class="top-like">
          <p class="seen-btn" @click.stop="gotoGoodDetail(l)">{{$t('common.see_now')}}</p>
          <p class="like-num" @click.stop="likeAdd(l,i)" :class="l.is_zan==1?'color-main':''">
            <van-icon name="like" class="heartBeat" />
            <span>{{l.zan}}</span>
            <!-- <span v-if="tt">{{l.zan}}</span>
               <span v-if="cb">{{$t('home.like')}}</span> -->
          </p>
        </div>
      </div>
    </div>

    <div v-if="items.length==0" class="no-box">
      <img class="juan-no" src="../../../assets/images/xuqiu-no.png" alt="">
      <p class="no-text">{{$t('good.no_data')}}</p>
    </div>
  </div>
</div>
  
</template>

<script>
// 晒单列表数据
import { Toast,Notify,ImagePreview } from "vant";

export default {
  name: "ttlist",
  props: {
    cb: Boolean,
    tt: Boolean,
    items: Array,
    sortfunc: Function,
    changeParentList:Function,
    changePList:Function
  },
  data() {
    return {
      lang:localStorage.getItem("langData") || 'th',
      imgSrc: require("@/assets/images/img-shou.png"),
      showTime: false,
      showLike: false,
      time: -1,
      like: -1,
      actions: [
        { text: this.$t("common.aesc"), value: 1 },
        { text: this.$t("common.desc"), value: -1 },
      ],
    };
  },

  methods: {
    showBigImg(images,startPosition){
      ImagePreview({
        images: images,
        closeable: true,
        startPosition
      });
    },
    gotoShaiHeight(id){  // 滑动 
      document.getElementById(id).scrollIntoView({ block: 'start', behavior: 'smooth' })
    },
    onSelectTime(data) {
      this.time = data.value;
      this.sortfunc(this.time == 1 ? true : false, "created_at");
    },
    onSelectLike(data) {
      this.like = data.value;
      this.sortfunc(this.like == 1 ? true : false, "zan");
    },
    likeAdd(item,index) {
      // Toast.success("点赞成功");
      this.$ajax.shaidanZan({
        saidanid:item.id
      })
      .then(res=>{
        if(res.code==200){
            Notify({ type: 'success', message: res.msg});
            this.changeParentList(item); 
            
        }
       
      })
    },
    gotoGoodDetail(item) {
      this.$router.push({
        name: "goods",
        query: {
          type: this.cb ? "cb" : "tt",
          isend: 1, // 已经结束
          gid: item.good_id,
          qishu: item.qishu,
        },
        hash:'#ttitem'+item.id
      });
    },
  },
  mounted() {
      
  },
};
</script>