export default [{
        path: "/wallet-replace",
        name: "wallet-replace",
        component: () =>
            import ("@/views/wallet/replace.vue")
    },
    {
        path: "/wallet-pvdetail",
        name: "wallet-pvdetail",
        component: () =>
            import ("@/views/wallet/detail.vue")
    },
    {
        path: "/wallet-thaiDetail",
        name: "wallet-thaiDetail",
        component: () =>
            import ("@/views/wallet/thaiDetail.vue")
    },
    {
        path: "/wallet-bidtokendetail",
        name: "wallet-bt_history",
        component: () =>
            import ("@/views/wallet/bt_history.vue")
    },
    {
        path: "/wallet-thairecharge",
        name: "wallet-thairecharge",
        component: () =>
            import ("@/views/wallet/thai_recharge.vue")
    },
];