<template>
  <div class="big-hint" v-if="show" >
    <div  class="wrapper-reg" @click.stop>
      <div class="block" id="wrapper">
        <div>
          <h6>{{$t('regRule.one')}}</h6>
          <p>{{$t('regRule.two')}}</p>
          <h6>{{$t('regRule.three')}}</h6>
          <p>{{$t('regRule.fore')}}</p>
          <h6>{{$t('regRule.five')}}</h6>
          <p>{{$t('regRule.six')}}</p>
          <h6>{{$t('regRule.seven')}}</h6>
          <p class="line34">{{$t('regRule.eight')}}</p>
          <p>{{$t('regRule.nine')}}</p>
          <p>{{$t('regRule.ten')}}</p>
          <h6>{{$t('regRule.eleven')}}</h6>
          <p>{{$t('regRule.twelve')}}</p>
          <h6>{{$t('regRule.thirteen')}}</h6>
          <p>{{$t('regRule.fourteen')}}</p>
          <h6>{{$t('regRule.fifteen')}}</h6>
          <p>{{$t('regRule.sixteen')}}</p>
          <p>{{$t('regRule.seventeen')}}</p>
          <p>{{$t('regRule.eighteen')}}</p>
          <p>{{$t('regRule.nineteen')}}</p>
          <p>{{$t('regRule.twenty')}}</p>
          <p>{{$t('regRule.twentyOne')}}</p>
          <p>{{$t('regRule.twentyTwo')}}</p>
          <p>{{$t('regRule.twentyThree')}}</p>
          <p>{{$t('regRule.twentyFour')}}</p>
          <p>{{$t('regRule.twentyFive')}}</p>
          <p>{{$t('regRule.taiAdd0')}}</p>
          <h6>{{$t('regRule.twentySix')}}</h6>
          <p>{{$t('regRule.twentySeven')}}</p>
          <p>{{$t('regRule.twentyEight')}}</p>
          <h6>{{$t('regRule.twentyNine')}}</h6>
          <p>{{$t('regRule.thirty')}}</p>
          <p>{{$t('regRule.thirtyOne')}}</p>
          <p>{{$t('regRule.thirtyTwo')}}</p>
          <h6>{{$t('regRule.thirtyThree')}}</h6>
          <p>{{$t('regRule.thirtyFour')}}</p>
          <p>{{$t('regRule.thirtyFive')}}</p>
          <p>{{$t('regRule.thirtySix')}}</p>
          <p>{{$t('regRule.thirtySeven')}}</p>
          <p>{{$t('regRule.thirtyEight')}}</p>
          <p>{{$t('regRule.thirtyNine')}}</p>
          <p>{{$t('regRule.forty')}}</p>
          <p>{{$t('regRule.fortyOne')}}</p>
          <p>{{$t('regRule.fortyTwo')}}</p>
          <p>{{$t('regRule.fortyThree')}}</p>
          <p>{{$t('regRule.fortyFour')}}</p>
          <p>{{$t('regRule.fortyFive')}}</p>
          <p>{{$t('regRule.fortySix')}}</p>
          <p>{{$t('regRule.fortySeven')}}</p>
          <p>{{$t('regRule.fortyEight')}}</p>
          <h6>{{$t('regRule.fortyNine')}}</h6>
          <p>{{$t('regRule.fifty')}}</p>
          <h6>{{$t('regRule.fiftyOne')}}</h6>
          <p>{{$t('regRule.fiftyTwo')}}</p>
          <p>{{$t('regRule.fiftyThree')}}</p>
          <p>{{$t('regRule.fiftyFour')}}</p>
          <div class="center">
            <van-button class="ikonw-btn" @click="ikonw">{{$t('regRule.ikonw')}}</van-button>  
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props:['showReg'],
  data(){
      return {
          show:false
      }
  },
  watch: {
    showReg:{
      deep: true,
      handler: function(val) {
        this.show = val;
      }
    }
  },
  methods:{
    ikonw(){
      this.$emit("toshowReg", false);
    },
  },
  mounted(){
  },
}
</script>
