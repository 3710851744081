<template>
  <div class="intl-tel-input allow-dropdown" style="height:100%;">
    <div class="flag-container">
      <div
        style="margin:0"
        class="selected-flag"
        :title="currentData.name + ': +' + currentData.dialCode"
        ref="clickdiv"
        @click.stop="hideSubMenu = !hideSubMenu"
      >
        <div :class="'iti-flag ' + currentData.code"></div>
        <div class="iti-code"><span class="span">+</span>{{currentData['dialCode']}}</div>
        <div class="iti-arrow"></div>
      </div>
      <ul class="country-list" v-show="!hideSubMenu">
        <li class="search-box">
          <van-search :placeholder="$t('login.country_code')"  class="area-search" @input="searchSome" v-model="keyword" @search="searchSome"/>
        </li>
        <li
          v-for="item in frontCountriesArray"
          :key="item.id"
          :class="'country ' + (item.code == currentCode ? 'highlight' : '')"
          @click.stop="liItemClick(item)"
        >
          <div class="flag-box">
            <div :class="'iti-flag ' + item.code"></div>
          </div>
          <span class="country-name">{{ item.name }}</span>
          <span class="dial-code">+{{ item.dialCode }}</span>
        </li>
        <li class="divider"></li>
        <li
          v-for="item in countriesArray"
          :key="item.id"
          :class="'country ' + (item.code == currentCode ? 'highlight' : '')"
          @click.stop="liItemClick(item)"
        >
          <div class="flag-box">
            <div :class="'iti-flag ' + item.code"></div>
          </div>
          <span class="country-name">{{ item.name }}</span>
          <span class="dial-code">+{{ item.dialCode }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>


<script>
  import countries from './area/countryList'

  export default {
    props: {
      toFront: {
        type: Array,
        default: () => {
          return []
        }
      },
      countryCode: {
        type: String,
        default: Object.keys(countries)[0],
        validator(code) {
          var clearCode = String(code).toLowerCase()
          return !!countries[clearCode]
        }
      }
    },

    data() {
      return {
        currentCode: this.countryCode,
        hideSubMenu: true,
        keyword:'',
        countriesArray:countries
      }
    },

    computed: {
      currentData() {
        return countries[this.currentCode]
      },
      frontCountriesArray() {
        const toFrontCodes = {}
        this.toFront.forEach((code) => {
          const stringCode = String(code)
          const needObj = countries[stringCode]

          if(needObj) {
            toFrontCodes[stringCode] = needObj
          }
        })
        return toFrontCodes
      },
      // countriesArray:{
      //     get(){
      //       const countryCopie = { ...countries }
      //       this.toFront.forEach((code) => {
      //         delete countryCopie[code]
      //       })
      //       return countryCopie
      //     },
      //     set(){

      //     }
      // }
    },

    watch: {
      countryCode(newCode) {
        this.setCountry(countries[newCode])
      }
    },

    methods: {
      searchSome(){
        let keys = Object.keys(countries);
        let arr = [];
        keys.forEach(item=>{
          if(item.indexOf(this.keyword)!=-1){
            arr.push(item);
          }
        })
        const countryCopie = { };
        arr.forEach(item=>{
          countryCopie[item] = countries[item];
        })
        this.countriesArray = {...countryCopie}
        
      },
      setCountry(item) {
        this.currentCode = item.code
        this.toFront.push(String(item.code))
        this.$emit('excountry', item)
      },
      liItemClick(item){
        this.currentCode = item.code; 
        this.hideSubMenu = true; 
        this.setCountry(item);
        this.keyword=''
        this.countriesArray = {...countries}
      }
    },

    mounted() {
      this.$emit('excountry', countries[this.countryCode])
    }
  }
</script>


