<template>
    <!-- 玩法与规则  -->
    <div class="big-hint" v-if="show" @click="show=false" >
        <div class=" wrapper-reg" @click.stop >
             <div class="play-rule block" >
                <div class="block-box">
                    <h2>{{$t('rule.txt')}}</h2>
                    <div class="ques-list">
                        <p class="rule_q">
                        1.{{$t('rule.cb_play_q')}}
                        </p>
                        <p class="rule_a">
                        {{$t('rule.cb_play_a')}} 
                        </p>
                        <p class="rule_a">
                        {{$t('rule.cb_play_a2')}} 
                        </p>
                    </div>
                    <div class="ques-list">
                        <p class="rule_q">
                        2.{{$t('rule.cb_rule_q')}}
                        </p>
                        <p class="rule_a">
                        {{$t('rule.cb_rule_a')}} 
                        </p>
                        <p class="rule_a">
                        {{$t('rule.cb_rule_a2')}} 
                        </p>
                         <p class="rule_a">
                        {{$t('rule.cb_rule_a3')}} 
                        </p>
                        <p class="rule_a">
                        {{$t('rule.cb_rule_a4')}} 
                        </p>
                    </div>
                    <div class="ques-list">
                        <p class="rule_q">
                        3.{{$t('rule.tt_play_q')}}
                        </p>
                        <p class="rule_a">
                        {{$t('rule.tt_play_a')}} 
                        </p>
                         <p class="rule_a">
                        {{$t('rule.q0')}} 
                        </p>
                        <p class="rule_a">
                        {{$t('rule.q1')}} 
                        </p>
                        <p class="rule_a">
                        {{$t('rule.q2')}} 
                        </p>
                        <p class="rule_a">
                        {{$t('rule.q3')}} 
                        </p>
                    </div>
                    <div class="ques-list">
                        <p class="rule_q">
                        4.{{$t('rule.tt_rule_q')}}
                        </p>
                        <p class="rule_a">
                        {{$t('rule.tt_rule_a')}} 
                        </p>
                        <p class="rule_a">
                        {{$t('rule.q4')}} 
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            show:false
        }
    },
    props:['changescroll'],
    watch:{
        show(v){
            this.changescroll(v);
        }  
    },
}
</script>

