import Loading from './loading/loading.vue';
import MyFoot from './myfoot.vue'
// import LoginCpt from "@/views/logins/index.vue"
import Register from "@/views/logins/register.vue"
import ForgotPass from "@/views/logins/forgotpass.vue"
// import Captcha from "@/views/logins/captcha.vue"
import Head from "./myhead.vue"
import AuctionList from "@/views/auction/components/list.vue"
import CBList from "@/views/result/components/cblist.vue"
import TTList from "@/views/result/components/ttlist.vue"
import MySwipe from "./myswipe.vue"
import MySwipeItem from "./myswipeitem.vue"

import AreaNum from "./areanum.vue"
import MyAuctionList from "@/views/mine/myacutionlist.vue"

import Calendar from "./calendar/index.vue"
import SmsDemo from "./sms.vue"

import MyList from "./mylist.vue"
import PlayRule from "./playrule.vue"
import RegRule from "./regrule.vue"

import IconSvg from "./iconSvg.vue"

import LineBtn from "./linebtn.vue"



export default {
    install: function(Vue) {
        Vue.component('Loading', Loading);
        Vue.component('MyFoot', MyFoot);
        // Vue.component('LoginCpt', LoginCpt);
        Vue.component('Register', Register);
        Vue.component('ForgotPass', ForgotPass);
        // Vue.component('Captcha', Captcha);
        Vue.component('Head', Head);
        Vue.component('AuctionList', AuctionList);
        Vue.component('CBList', CBList);
        Vue.component('TTList', TTList);

        Vue.component('MySwipe', MySwipe);
        Vue.component('MySwipeItem', MySwipeItem);
        Vue.component('AreaNum', AreaNum);
        Vue.component('MyAuctionList', MyAuctionList);


        Vue.component('Calendar', Calendar);
        Vue.component('SmsDemo', SmsDemo);

        Vue.component('MyList', MyList);
        Vue.component('PlayRule', PlayRule);
        Vue.component('RegRule', RegRule);
        Vue.component('IconSvg', IconSvg);

        Vue.component('LineBtn', LineBtn);
        





    }
}