

<script>
export default {
    props:{
        sharelink:String
    },
    render(h,params){
        var that = this;
        return  h('div',[
            h('div',
                {
                    class:{
                        'line-it-button ':true,
                        'share-li ':true,
                        'bounce':true,
                    },
                    attrs:{
                        'data-lang':'zh_Hant',
                        'data-type':'share-b',
                        'data-url':that.sharelink,
                        'data-color':'default',
                        'data-size':'small',
                        'data-count':"true",
                        // 'style':'display: none'
                    }
                }
                ,[  h('svg',{
                    class:{
                        'share-icon':true,
                    },
                    attrs:{
                        'aria-hidden':true,
                    }
                    },[
                        h('use',{
                            attrs:{
                                "xlink:href":"#iconLineMessenger"
                            }
                        })
                    ]),
                    h('p',{
                    
                    }, 'Line Messenger'),
                
                ]),
                // h('script',{
                //     attrs:{
                //         'src':'https://d.line-scdn.net/r/web/social-plugin/js/widget/button.1.7.2.0.js',
                //         'async':'async',
                //         'defer':'defer',
                //     }
                // }),
                h('script',{
                    attrs:{
                        'src':'https://www.line-website.com/social-plugins/js/thirdparty/loader.min.js',
                        'async':'async',
                        'defer':'defer',
                    }
                }),
   
        ])
    }
}
</script>


