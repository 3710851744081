<template>
  <div class="myauction">
    <div class="auction-list" v-if="list.length>0">
      <MyList  :listadd="listadd" ref="mylist" :refreshajax="refresh" >
        <div class="cb-item" v-for="(l,i) in list" :key="i">
          <div v-if="l.is_end==1">
            <img v-if="lang" class="loft" :src="l.isWinner==0?require('@/assets/images/'+lang+'_goldoff.png'):require('@/assets/images/'+lang+'_goldon.png')" alt="">
            <div class="cb-item-top">
              <div class="top-one">
                <img class="item-img" alt="" v-lazy="l.thumb">
                <div class="item-right">
                  <!-- {{l.is_pay}} -- {{l.winning_is_expired}} -->
                  <div class="item-right-one">{{l.qishu_trans}}  </div>
                  <!-- :class="lang=='cn'?'':'margintop0'" -->
                  <h2 class="item-right-name van-ellipsis"  >{{l.title}}</h2>
                  <!-- {{l.is_mail_winning}} {{l.is_sell_winning}} -->
                  <div class="item-right-time" :class="lang=='cn'?'':'margintop2'">
                    <p>{{$t('common.open_time')}}:</p>
                    <p class="time-detail" >
                      {{l.starttime || l.winning_time}}
                    </p>
                  </div>
                  <div class="item-right-jiang"  :class="lang=='cn'?'':'flex-column'">
                    <p>
                      <span>{{$t('common.lucker')}}:</span>
                      <span>{{l.winner_id | yinfour}}</span>
                    </p>
                    <p class="jiang-two" v-if="cb">
                      <span>{{$t('common.jiang_code')}}:</span>
                      <span>{{l.lucky_num}}</span>
                    </p>
                    <p class="jiang-two" v-if="tt">
                      <span>{{$t('common.luck_price')}}:</span>
                      <span>${{l.betprice | number_format}}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="top-common" v-if="cb">
                <div class="top-func color-gard">
                  <span>  {{$t('common.open_func')}}: {{l.winningFormula}} <i @click="showCbRule" class="iconfont icongantanhao seeinfo" > </i>  </span>
                  <!-- -->
                </div>
                <div class="top-my">
                  <span @click="gotoMyCodeList('mine-code',l)" class="my-btn">{{$t('common.seen')}}{{lang=='en'?' ':''}}{{$t('mine.myjiangcode')}}</span>
                  <span class="myjoinnum">  {{l.me_join_num}}/{{l.total_need}}</span>
                  <!-- <span>{{$t('mine.myjiangcode')}}:</span> <span v-if="l">{{l.lucky_num}}</span> <span @click="gotoMyCodeList('mine-code',l)" class="my-btn">{{$t('common.seen')}}</span> -->
                </div>
              </div>
              <div class="top-common" v-if="tt">
                <div class="top-func">
                  <p>{{$t("mine.mygoldlist")}}</p>
                  <p class="more-btns" @click="showMoreRecord(l.record)">
                    <span class="more-text">{{$t('common.more')}}</span>
                    <van-icon name="arrow" class="more-icon" />
                  </p>
                </div>
                <ul class="gold-list">
                  <li class="li-item" v-for='(v,i) in l.record' :key="i" v-if="i<5">
                    <p class="li-item-time">{{v.send_time}}</p>
                    <p class="li-item-sec">{{$t('mine.add')}}{{ parseInt(v.amount) | time_type}}{{$t('mine.second')}}</p>
                    <p class="li-item-money">${{v.amount | number_format}}</p>
                  </li>
                </ul>
              </div>
            </div>
            <div class="cb-item-end" v-if="cb">
              <div v-if="l.isWinner==1" class="btn-list">
                <template v-if="!l.is_mail_winning&&!l.is_sell_winning">
                  <van-button class="btn-you mybtn" :class="lang=='en'?'spanen':''"  @click="gotoAddress('mine-address',l)">
                    {{$t('mine.youji')}}
                  </van-button>
                  <van-button class="btn-hui mybtn" @click="repayChange(l)">
                    {{$t('mine.huishou')}}
                  </van-button>
                </template>
                <template v-else>
                  <van-button disabled  class="btn-you mybtn noborder" ></van-button>
                  <van-button class="btn-you mybtn" :class="lang=='en'?'spanen':''"  @click="gotoSeeEmail('mine-viewinfo',l)">
                    {{$t('wallet.view_info')}}
                  </van-button>
                </template>
                <div v-if="l.isSaidan==0" class="btn-shai mybtn" @click="gotoShaidan(l)">
                    {{$t('home.goshai')}}+5PV 
                  <p class="mp aftermove"></p>
                </div>
                <van-button @click="gotogoodDetail(l,1)" v-if="l.isSaidan==1" class="btn-disabled mybtn">
                  {{$t('mine.already_sai')}}
                </van-button>

                <van-button class="btn-share" @click="gotoShareWai(l)"> <i class="iconfont iconfenxiang"></i> </van-button>
                
              </div>
            </div>
            <div class="cb-item-end" v-if="tt">
              <!-- <p>{{l.winning_is_expired}}</p> -->

              <!-- 已经支付 v-if="l.isWinner==1&&l.is_pay==1"  -->
              <div v-if="l.isWinner==1&&l.is_pay==1" class="btn-list">
                <template v-if=" !l.is_mail_winning&&!l.is_sell_winning">
                  <van-button class="btn-you mybtn" :class="lang=='en'?'spanen':''"  @click="gotoAddress('mine-address',l)">
                    {{$t('mine.youji')}}
                  </van-button>
                  <van-button class="btn-hui mybtn" @click="repayChange(l)">
                    {{$t('mine.huishou')}}
                  </van-button>
                </template>
                <template v-else>
                  <van-button class="btn-you mybtn noborder"  disabled></van-button>
                  <van-button class="btn-you mybtn" :class="lang=='en'?'spanen':''"  @click="gotoSeeEmail('mine-viewinfo',l)"  >
                    {{$t('wallet.view_info')}}
                  </van-button>
                 
                </template>
                <!-- <van-button v-if="l.isSaidan==0" class="btn-shai mybtn" @click="gotoShaidan(l)">
                  {{$t('home.goshai')}}+5PV
                </van-button> -->
                <div v-if="l.isSaidan==0" class="btn-shai mybtn" @click="gotoShaidan(l)">
                    {{$t('home.goshai')}}+5PV 
                  <p class="mp aftermove"></p>
                </div>
                <van-button  v-if="l.isSaidan==1" class="btn-disabled mybtn">
                  {{$t('mine.already_sai')}}
                </van-button>
                <van-button class="btn-share" @click="gotoShareWai(l)"> <i class="iconfont iconfenxiang"></i> </van-button>
              </div>

              <!-- 48h 内还未支付   -->
              <div v-if="l.isWinner==1&&l.winning_is_expired==0&&l.is_pay==0"  class="btn-list"> 
                  <p class="p-value">
                    $ {{l.betprice | number_format}}
                  </p>
                <van-button  class="btn-shai mybtn"  @click="gotopaybytt(l)">
                  {{$t('add.go_pay')}}
                </van-button>
              </div>

              <!-- 48h后  未支付 -->
              <div v-if="l.isWinner==1&&l.winning_is_expired==1&&l.is_pay==0" class="btn-list btn-bg"> 
                  <p class="p-notpay">
                    {{$t('add.not_pay')}}
                  </p>
              </div>
            </div>
          </div>
          
          <div v-if="l.is_end!=1" class="list-item"  @click="seeGoodDetail(l,0)">
              <img class="item-img" alt="" v-lazy="l.thumb" >
              <div class="item-right">
                  <div class="item-right-one">
                      <span>{{l.qishu_trans}}</span>
                      <span v-if="cb">${{(l.total_amount?l.total_amount:'0') | number_format}}</span>
                      <div v-if="tt" class="item-right-price">
                          <span>{{$t('common.now_price')}}</span>
                          <span>${{(l.betprice?l.betprice:'0') | number_format}}</span>
                      </div>
                  </div>
                  <h2 class="item-right-name van-ellipsis">{{l.title}} </h2>
                  <div v-if="cb" class="item-right-process">
                      <van-progress v-if="cb&&l.active_id==1" :percentage="parseInt((l.join_num/l.total_need)*100)" color="#DF561C" :show-pivot="false"/>
                  </div>
                  <div v-if="cb" class="item-right-total">
                    <p>{{$t('home.has_enter')}}</p>
                    <p>
                      <span class="color-main">{{l.join_num}}</span>
                      <span>/</span>
                      <span>{{l.total_need}}</span>
                    </p>
                  </div>

                  <div v-if="tt&&l.countdown" class="item-right-last">
                       <template  v-if="parseInt(l.countdown.day || 0) * 24 * 3600 * 1000 + parseInt(l.countdown.hours ||  0)  * 3600 * 1000 + parseInt(l.countdown.mins||  0)  *60 * 1000 + parseInt(l.countdown.second ||  0)  * 1000>0&&!l.timeend">
                          <img class="item-lou" :src="require('@/assets/gif/lou.gif')" alt="">
                          <van-count-down :key="l.countKey" @finish="timeendaction(l)" millisecond  format="HH:mm:ss:SS" :time="parseInt(l.countdown.day || 0) * 24 * 3600 * 1000 + parseInt(l.countdown.hours ||  0)  * 3600 * 1000 + parseInt(l.countdown.mins||  0)  *60 * 1000 + parseInt(l.countdown.second ||  0)  * 1000" class="item-end" >
                          </van-count-down>
                       </template>
                       <div class="item-wait" v-else>
                            <p class="p-end " v-if="!l.resultOut&&l.is_end!='1'" ><van-loading class="p-icon" color="#DF561C" /> {{$t('add.wait_for_result')}}</p>
                            <p class="p-result " v-else >{{$t('add.ended')}}</p>
                        </div>
                  </div>
              </div>
          </div>
        </div>
      </MyList> 
    </div>

    

    <div v-else class="no-box">
      <img class="juan-no" src="../../assets/images/xuqiu-no.png" alt="">
      <p class="no-text">{{$t('mine.right_pai')}}</p>
    </div>

    
  </div>

</template>


<script>
import {passReg} from "@/common/validate"
import {randomString} from '@/common'
import {Notify} from "vant"
export default {
  props: {
    cb: Boolean,
    tt: Boolean,
    change: Function,
    list: Array,
    getRecordList:Function,
    changeshowttpay:Function,
    listadd:Function,
    timeendaction:Function,
    refresh:Function,
    changeShareItem:Function,
    changecbruleShow:Function
  },
  data() {
    return {
      recordlist:[], 
      show:true,
      repay: false,
      imgSrc: require("@/assets/images/img-shou.png"),
      lang:''
    };
  },
  mounted(){
        var langData = localStorage.getItem("langData")  ;
        if(langData){
          // 有数据但是不是 cn . en th 
            if(langData!="cn"&&langData!="en"&&langData!="th"){
              localStorage.setItem("langData","en");   
            }
        }else{
          localStorage.setItem("langData","en"); 
        }
        this.lang = localStorage.getItem("langData");  
  },
  methods: {
    showCbRule(){
      this.changecbruleShow();
    },
    gotoShareWai(item){
      this.changeShareItem(item)
    },
    gotoSeeEmail(name,item){
      this.$router.push({
          name,
          query:{
            win_orderid:item.winning_orderid,
            active_id:item.active_id   // 判断是 tt 还是 cb 
          }
        });
    },
    gotopaybytt(item){
      this.changeshowttpay(item)
    },

    gotoMyCodeList(name,list){
      // 查看这期抽奖码 
      this.$router.push({
        name,
         query:{
           good_id:list.good_id,
            qishu:list.qishu,
         }
      })
    },
    gotoAddress(name,item){
        this.$router.push({
          name,
          query:{
            win_orderid:item.winning_orderid,
            is_you:true,
            active_id:item.active_id   // 判断是 tt 还是 cb 
          }
        });
      // if(item.is_mail_winning || item.is_sell_winning){   // 判断是否邮寄 或者被回收  0 没有 
      //   var msg = "";
      //   Notify({
      //       // message: this.$t('add.has_emial'),
      //       message: this.$t('add.has_done'),            
      //       color: '#ad0000',
      //       background: '#ffe1e1',
      //   });
      // }else{
        
      // }
    },
    showMoreRecord(record){
      // console.log(record)
      // this.recordlist = record;
      // this.show = true;
      this.getRecordList(true,record)
    },
    repayChange(item) {
      this.change(true,item);
      // if(item.is_mail_winning || item.is_sell_winning ){
      //   Notify({
      //       // message: this.$t('add.has_sall'),
      //       message: this.$t('add.has_done'),            
      //       color: '#ad0000',
      //       background: '#ffe1e1',
      //   });
      // }else{
          
      // }
    },
    gotowhere(name) {
      this.$router.push({
        name,
      });
    },
    seeGoodDetail(item,isend) {
      this.$router.push({
        name: "goods",
        query: {
          type: item.active_id == "1" ? "cb" : "tt",
          isend: isend, // 还没有结束
          gid: item.good_id,
          qishu: item.qishu,
        },
      });
    },
    gotogoodDetail(item,isend) {
      this.$router.push({
        name: "goods",
        query: {
          type: item.active_id == "1" ? "cb" : "tt",
          isend: isend, // 已经结束
          gid: item.good_id,
          qishu: item.qishu,
        },
      });
    },
    gotoShaidan(item) {
      this.$router.push({
        name: "result-edit",
        query: {
          gid: item.good_id,
          qishu: item.qishu,
          orderid: item.winning_orderid,
        },
      });
      localStorage.removeItem("goodonedetail")
      localStorage.setItem("goodonedetail",JSON.stringify(item))
    },
  },
};
</script>