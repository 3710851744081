<template >
    <div >
        <p v-if="!isSending" size="small"  @click="getCaptcha"  class="sms-btn">{{txt}}</p>
        <p v-if="isSending"  size="small"    class="sms-btn color-disable">{{txt}}</p>
    </div>
</template>


<script>

var timer = null;
import {phoneReg,smsReg} from "@/common/validate"
import {Toast,Notify} from "vant"
export default {
    props:['phone','type','area'],
    data(){
        return {
            count:120,
            txt:this.$t('login.send_captcha'),
            isSending:false,
            phoneReg,
            
        }
    },
    methods:{
        getCaptcha(){
           
            if(this.phone&&this.phoneReg.test(this.phone)){
                this.txt = this.count + ' S';
                this.isSending = true;
                timer = setInterval(()=>{
                    if(this.count>0){
                        this.count--;
                        this.txt = this.count + ' S';
                        this.isSending = true;
                    }else{
                        // timer = null;
                        clearInterval(timer)
                        this.count = 120;
                        this.txt = this.$t('login.send_captcha');
                        this.isSending = false;
                    }
                },1000);
                
                this.$ajax.sendMsg({
                    area:this.area,
                    phone:this.phone,
                    type:this.type,
                })
                .then(res=>{
                    if(res.code==200){
                        Notify({ type: 'success', message: res.msg });
                    }
                })

            }else{
                 Toast(this.$t("login.sure_sms"))   
            }   
        }
    }
}
</script>
