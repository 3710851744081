<template>
<div>
   <RegRule @toshowReg="getshowReg" :showReg="showReg"></RegRule>
   <div class="register login-box">
        <van-nav-bar
            :title="$t('common.register')"
            fixed
            @click-left="onClickLeft"
            class="my-head null-head"
            :style="{background:'transparent'}"
        >
            <template #left   >
                <van-icon name="arrow-left"   />
            </template>
            <template #right  >
                <div class="login-one">
                    <div class="change" @click="showPopup">
                        <p class="qi-box">
                            <svg class="guoqi" aria-hidden="true">
                                <use :xlink:href="guoqiclass"></use>
                            </svg>
                            <span>{{lastLang | langtext }}</span>
                        </p>
                        <van-icon name="arrow-down" class="vicon"/>
                    </div>
                </div>
            </template>
        </van-nav-bar>
        <div class="register-box">
            <div class="box-color"></div>
            <div class="register-content">
                <van-form @submit="onSubmit" class="my-form" ref="zhuceform">
                    <van-field
                        v-model="signup.nickname"
                        name="nickname"
                        :label="$t('login.nickname')"
                        :placeholder="$t('login.enter')+(lang=='en'?' ':'')+$t('login.nickname')"
                        :rules="[{ required: true, message: $t('login.required_nickname') }]"
                    />                   
                    <div class="van-cell van-field van-nohidden">
                        <div class="van-cell__title van-field__label">
                            <span>{{$t('login.phone')}}</span>
                        </div>
                        <div class="van-cell__value van-field__value van-phone">
                            <div class="tel-qu">
                                <AreaNum  country-code="th"  @excountry="getCountryItem" />
                            </div>
                            <div>
                                <div class="van-field__body">
                                    <input  @input="checkPhone" @blur="phoneBlur" type="tel" v-model="signup.phone" name="phone" :placeholder="$t('login.enter')+(lang=='en'?' ':'')+$t('login.phone')" class="van-field__control width100input">
                                    <div v-if="!phoneFlag" class="van-field__error-message qu-error">{{phoneErr}}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <van-field
                        maxlength="6"
                        v-model="signup.captcha"
                        type="text"
                        name="captcha"
                        :label="$t('login.captcha')"
                        :placeholder="$t('login.enter')+(lang=='en'?' ':'')+$t('login.captcha')"
                        :rules="[{ required: true, message:$t('login.required_captcha')},{pattern:smsReg,message:$t('login.ture_captcha'),trigger:'onBlur'}]"
                    >
                        <template #button>
                            <p v-if="!isSending" size="small"  @click="getCaptcha"  class="sms-btn">{{$t('login.send_captcha')}}</p>
                            <p v-if="isSending"  size="small"    class="sms-btn color-disable">{{count}} S</p>
                        </template>
                    </van-field>
                    
                    <van-field
                        v-model="signup.password"
                        :type="pt1"
                        name="password"
                        :right-icon="pt1=='password'?'eye':'closed-eye'"
                        @blur="validatorPass"
                        @click-right-icon="changePassType1"
                        :label="$t('login.password')"
                        :placeholder="$t('login.enter')+(lang=='en'?' ':'')+$t('login.login_pass')+$t('login.pass_rule')"
                        :rules="[
                        { required: true, message: $t('login.required_pass') },
                        {
                            pattern:passPattern,message:$t('login.pass_error'),trigger:'onBlur'
                        }
                        ]"
                    />
                    <van-field
                        v-model="signup.repassword"
                        :type="pt2"
                        name="repassword"
                        :right-icon="pt2=='password'?'eye':'closed-eye'"
                        @click-right-icon="changePassType2"
                        :label="$t('login.confirm')+(lang=='en'?' ':'')+$t('login.password')"
                        :placeholder="$t('login.enter')+(lang=='en'?' ':'')+$t('login.confirm')+(lang=='en'?' ':'')+$t('login.password')"
                        :rules="[
                            { required: true, message: $t('login.required_dbpass') },
                            {
                                pattern:passPattern,message:$t('login.pass_error'),trigger:'onBlur'
                            },
                            {
                                validator:validatordbPass,message:$t('login.pass_equal_error'),trigger:'onBlur'
                            }
                        ]"
                    />
                    <van-field
                        v-model="signup.pin"
                        :type="pt3"
                        name="pin"
                        :right-icon="pt3=='password'?'eye':'closed-eye'"
                        @blur="validatorsecPass"
                        @click-right-icon="changePassType3"
                        :label="$t('login.second_pass')"
                        :placeholder="$t('login.enter')+(lang=='en'?' ':'')+$t('login.second_pass')"
                        :rules="[
                        { required: true, message: $t('login.required_sec_pass') },
                        { pattern:passPattern,message:$t('login.secpass_error'),trigger:'onBlur'}
                        ]"
                    />
                    <van-field
                        v-model="signup.repin"
                        :type="pt4"
                        name="repin"
                        :right-icon="pt4=='password'?'eye':'closed-eye'"
                        @click-right-icon="changePassType4"
                       :label="$t('login.confirm')+(lang=='en'?' ':'')+$t('login.second_pass')"
                        :placeholder="$t('login.confirm')+(lang=='en'?' ':'')+$t('login.second_pass')"
                        :rules="[{ required: true, message: $t('login.required_sec_dbpass') },
                            { pattern:passPattern,message:$t('login.secpass_error'),trigger:'onBlur'},
                            {
                                validator:validatorsecPassEqual,message:$t('login.secpass_equal_error'),trigger:'onBlur'
                            }
                        ]"
                    />
                    <van-field
                        v-model="signup.recommend"
                        type="text"
                        name="recommend"
                        :label="$t('login.inivate_code')"
                        :placeholder="$t('login.enter_code')"
                        
                    />
                    <!-- :rules="[{ required: true, message: $t('login.required_yaocode') }]" -->
                    <div class="register-agree">
                        <van-checkbox :class="signup.agree?'agree':'noagree'" v-model="signup.agree" > {{$t('login.agree_tips')}}</van-checkbox>
                        <div v-if="!showReg" @click="showRegRule" class="showRegRule"></div>
                    </div>
                    <div class="btn-box register-btn">
                        <van-button class="login-btn"  @click="btnClick" block  native-type="submit">
                        {{$t('common.register')}}
                        </van-button>
                    </div>
                </van-form>
            </div>
        </div>
    </div>

    <van-popup class="login-popup" v-model="show" position="bottom"  :style="{ height: '5.3333rem' }" duration="0.2" >
        <div class="login-popup-box">
            <div class="pop-top">
                <p>{{$t('login.set_lang')}}</p>
                <van-icon name="cross" class="pop-close" @click="show=false" />
            </div>
            <div class="pop-btns">
                <van-button class="btn-normal" @click="changeLang('cn')" :class="lang=='cn'?'btn-active':''" >中文</van-button>
                <van-button class="btn-normal"  @click="changeLang('th')" :class="lang=='th'?'btn-active':''">ไทย</van-button>
                <van-button class="btn-normal"  @click="changeLang('en')" :class="lang=='en'?'btn-active':''">English</van-button>
            </div>
            <van-button class="sure-btn" @click="sureChange">{{$t('login.sure')}}</van-button>
        </div>
    </van-popup>
</div>
</template>

<script>
import {phoneReg,passReg,smsReg} from "@/common/validate"
import {Toast,Notify} from "vant";
import {sendMsg} from "@/api";
import {vantLocales} from "@/main.js"
var timer = null ;
export default {
    name:"register",
    data(){
        return {
            signup:{
                agree:false,
                area:'66',
                recommend:this.$route.query.recommend_code  // 有就直接先显示 
            },
            phoneFlag:true,
            phoneErr:'',
            passPattern:passReg,
            smsReg,
            pt1:'password',
            pt2:'password',
            pt3:'password',
            pt4:'password',
            count:120,
            txt:this.$t('login.send_captcha'),
            isSending:false,
            showReg: false,
            lang:localStorage.getItem("langData") || 'en',
            lastLang:localStorage.getItem("langData") || 'en',
            show:false,
            outLink:false,
        }
    },
    computed:{
        guoqiclass(){
            if(this.lastLang=="cn"){
                return "#iconzhongguo"
            }else if(this.lastLang=="en"){
                return "#iconyingguo"
            }else{
                return "#icontaiguo"
            }
            return this.lastLang
        },
    },
    mounted(){
        
    },
    watch:{
        showReg:{
            deep: true,
            handler:function(val){
                this.signup.agree = !val;
            },
        }
    },
    methods:{
        onClickLeft(){
            // console.log('123')
            if(this.$route.query.recommend_code){
                this.$router.push({name:'signin'})
            }else{
                this.$router.go(-1);
            }
        },
        changeLang(lang){
            this.lang = lang;
        },
        sureChange(){
            localStorage.setItem('langData',this.lang);
            this.$i18n.locale = this.lang;
            this.lastLang = this.lang;
            this.show = false;
            vantLocales(this.lang)
        },
        showPopup(){
            this.lang = localStorage.getItem("langData") || 'th',
            this.show = true;
        },
        showRegRule(checked){
            this.showReg = true;
        },
        getshowReg(val){
            this.showReg = val;
        },
        changePassType1(){
            this.pt1 = this.pt1 == "password"?"text":"password"
        },
        changePassType2(){
            this.pt2 = this.pt2 == "password"?"text":"password"
        },
        changePassType3(){
            this.pt3 = this.pt3 == "password"?"text":"password"
        },
        changePassType4(){
            this.pt4 = this.pt4 == "password"?"text":"password"
        },
        validatorPass(val){
            if(this.signup.repassword){
                this.$refs.zhuceform.validate('repassword');
            }
        },
        validatordbPass(val){
            if(this.signup.password){
                return val === this.signup.password;
            }  
        },
        validatorsecPass(){
            if(this.signup.repin){
                this.$refs.zhuceform.validate('repin');
            }
        },
        validatorsecPassEqual(val){
            if(this.signup.pin){
                return val === this.signup.pin;
            }  
        },
        phoneBlur(){
            if(!this.signup.phone){
                this.phoneErr = this.$t('login.empty_phone');
                this.phoneFlag = false;
            }
        },
        checkPhone(){
            if(this.signup.phone){
                if(phoneReg.test(this.signup.phone)){
                    // 通过
                    this.phoneFlag = true;
                    this.phoneErr = ""
                }else{
                    this.phoneFlag = false;
                    this.phoneErr = this.$t("login.err_phone")
                }
            }else{
                // null
                this.phoneErr = this.$t('login.empty_phone');
                this.phoneFlag = false;
            }
        },

        onSubmit(value){
            
            // console.log(this.signup.agree)
            if(!this.phoneFlag){
                this.checkPhone(); 
            }else{
                if(!this.signup.agree){
                    Toast(this.$t('login.agree_warn'));
                }else{
                    // console.log(value)
                    
                    // Notify("注册成功,立即前往登录")
                    this.$ajax.register({
                        
                        password:this.signup.password,
                        repassword:this.signup.repassword,
                        pin:this.signup.pin,
                        repin:this.signup.repin,
                        recommend:this.signup.recommend,
                        area:this.signup.area,
                        phone_number:this.signup.phone,
                        email:'',
                        username:"",
                        nickname:this.signup.nickname,
                        code:this.signup.captcha,
                    })
                    .then(res=>{
                        if(res.code==200){
                            Toast(this.$t('login.welcome_login'));
                            this.$router.push({name:'signin',query:{phone:this.signup.phone}})
                        }
                    })
                    .catch(err=>{
                        console.log(err);
                    })
                }
            }
            
        },

        btnClick(){
            // 手机检测
            this.checkPhone();
        },
        getCountryItem(data){
            this.signup.area = data.dialCode;
        },
        getCaptcha(){
           
            if(this.signup.phone&&this.phoneFlag){
                this.txt = this.count + ' S';
                this.isSending = true;
                timer = setInterval(()=>{
                    if(this.count>0){
                        this.count--;
                        this.txt = this.count + ' S';
                        this.isSending = true;
                    }else{
                        // timer = null;
                        clearInterval(timer)
                        this.count = 120;
                        this.txt = this.$t('login.send_captcha');
                        this.isSending = false;
                    }
                },1000);
                
                this.$ajax.sendMsg({
                    area:this.signup.area,
                    phone:this.signup.phone,
                    type:3,
                })
                .then(res=>{
                      Notify({ type: 'success', message: res.msg });
                })

            }else{
                 Toast(this.$t("login.sure_sms"))   
            }
            
        }
    }
}
</script>

