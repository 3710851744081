export default [
    {
        path: "/usdt-list",
        name: "usdt-list",
        component: () =>
            import ("@/views/wallet/usdt/list.vue")
    },
    {
        path: "/usdt-index",
        name: "usdt-index",
        component: () =>
            import ("@/views/wallet/usdt/index.vue")
    },
    {
        path: "/usdt-recharge",
        name: "usdt-recharge",
        component: () =>
            import ("@/views/wallet/usdt/recharge.vue")
    },
];