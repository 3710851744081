export default [{
        path: "/signin",
        name: "signin",
        component: () =>
            import ("@/views/logins/signin.vue")
    },
    {
        path: "/register",
        name: "register",
        component: () =>
            import ("@/views/logins/register.vue")
    },
    {
        path: "/forgotpass",
        name: "forgotpass",
        component: () =>
            import ("@/views/logins/forgotpass.vue")
    },
    {
        path: "/resetloginpass",
        name: "resetloginpass",
        component: () =>
            import ("@/views/logins/resetloginpass.vue")
    },
    {
        path: "/quicklogin",
        name: "quicklogin",
        component: () =>
            import ("@/views/logins/quicklogin.vue")
    },

];